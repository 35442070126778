<template>
  <div class="ShowPage">
    <CreekShow 
      :showName="showName"
    />
  </div>
</template>

<script>
import CreekShowPage from '@/lib/creek/views/ShowPage'
export default {
  name: 'ShowPage',
  data(){
    return {}
  },
  props: {
    showName: {
      type: String
    }
  },
  methods: {

  }
}
</script>